export default [
  {
    year: 2019,
    walks: [
      {
        month: 12,
        actions: [
          '원광디지털대학교 웰니스문화관광학과 전공과목 채택 (웰니스 밸런스워킹PT)',
        ]
      },
      {
        month: 11,
        actions: [
          '자문위원 위촉(중앙대 교육대학원 교육학과 교수/ 가천대 운동재활복지학과 교수/ 경복대 의료미용과 교수/호남대 스포츠레저학과 교수/ 원광디지털대 웰니스문화관광학과 교수)',
          '2020 밸런스워킹PT 임상 프로젝트 및 심사위원 워크숍 주최·주관'
        ]
      },
      {
        month: 10,
        actions: [
          '제3회 밸런스워킹PT 경기대회 개최',
        ]
      },
      {
        month: 9,
        actions: [
          '순천신대건강생활지원센터와 업무협약 체결·체어PT 복합운동 연구 진행',
          '원광디지털대학교 산학협력 체결',
        ]
      },
      {
        month: 7,
        actions: [
          '2019 밸런스워킹PT 리더학교 주최·주관',
        ]
      },
      {
        month: 6,
        actions: [
          '한국여성체육협회 학회지 밸런스워킹PT 운동 효과 연구 논문 등재',
        ]
      },
      {
        month: 2,
        actions: [
          '나주시보건소 노인봅합운동 프로젝트 사전검사',
          '중앙대학교 체육교육학과/중앙대학교 학교체육연구소 협약체결',
        ]
      },
      {
        month: 1,
        actions: [
          '밸런스워킹PT 경남지사(지사장 송정임) 개소',
          '제1회 밸런스워킹PT 웰니스 경기대회',
          '나주시보건소와 함께 노인 복합운동 연구 진행',
          '호남대학교 스포츠레저학과 MOU 업무협약 체결',
        ]
      },
    ]
  },
  {
    year: 2018,
    walks: [
      {
        month: 12,
        actions: [
          '밸런스워킹PT체력관리사 프로과정 교육',
          '강북구보건소 주관 강북구 동별 걷기협회  특강',
          '전남항공소방대 산림항공관리소 조종사 특강',
          '밸런스워킹PT 김태민 대표 국가유공자 지정',
        ]
      },
      {
        month: 11,
        actions: [
          '밸런스워킹PT 창립 3주년 기념 \'2018밸런스워킹PT인의날\' 행사',
          '김태민 대표 자서전 \'행복을 주는 밸런스워킹PT\' 발간',
          '2018 대한민국브랜드대상,아시아브랜드대상 최우수건강브랜드부문 대상 수상',
          '나주시보건소 2018 밸런스워킹PT 지도사 역량강화 교육',
        ]
      },
      {
        month: 10,
        actions: [
          '제99회 전국체육대회기념 제56회 한국체육학회 학술발표회 겸 제30회 88서울올림픽기념국제스포츠과학학술대회 논문 발표',
          '제2회 밸런스워킹PT 경기대회 개최',
        ]
      },
      {
        month: 8,
        actions: [
          '(주)밸런스로 개소',
          '밸런스워킹PT 재활교육센터 개소',
          '(사)밸런스워킹PT코리아 개소',
          '청원보건소 삼성SDI  \'밸런스워킹PT와 함께 건강한 일터 만들기 12주 프로젝트\'',
        ]
      },
      {
        month: 7,
        actions: [
          '청주지역 김태민대표 초청 특강',
          '한국교회노인학교 지도자세미나',
          '충남 생활체육회 및 세종시 생활체육회 지도자 실기 교육 밸런스워킹PT',
          '2018 밸런스워킹PT 리더자학교',
        ]
      },
      {
        month: 6,
        actions: [
          '서울지방경찰청 제4기동단 체력강화훈련',
          '서울여자대학교 2018 하계직원연수 밸런스워킹PT',
          '밸런스워킹PT와 함께하는 폐경기 여성 건강 프로젝트 진행',
        ]
      },
      {
        month: 5,
        actions: [
          '밸런스워킹PT 남원지사(지사장 이상진) 개소',
        ]
      },
      {
        month: 3,
        actions: [
          '제1회 아쿠아밸런스워킹PT아쿠아지도사 교육과정',
          '기상청 건강관리 특강',
          '밸런스워킹PT 전북지사(지사장 강성미) 개소',
          '\'SBS 고향이 보인다\' 자세교정운동 밸런스워킹PT 소개',
        ]
      },
      {
        month: 2,
        actions: [
          '나주시보건소 단체 지도자 양성 교육',
          '호남지역 김태민대표 초청 ',
          '노원구청 치매지원센터 특강',
        ]
      },
      {
        month: 1,
        actions: [
          '나주시보건소 온 시민 워킹 홀릭을 위한 MOU  협약체결',
          '노벨요양병원 요양환자 건강증진을 위한 MOU 협약',
          '제 61회 노인학교지도자 교육 세미나 - 활동적 노년을 위한 밸런스워킹PT ',
          '아쿠아밸런스워킹PT지도사 자격 등록 완료 (등록번호:2018-000397)',
        ]
      },
    ]
  },
  {
    year: 2017,
    walks: [
      {
        month: 12,
        actions: [
          '2017 대한민국브랜드대상 모델대상 건강운동부문 대상 수상',
          '2017 아시아문예대상 산업통상부위원장상 수상 및 홍보대사 선정 ',
          '2017 대한민국청소년대상 지도자대상 수상',
        ]
      },
      {
        month: 11,
        actions: [
          '2017 대한민국브랜드대상 모델대상 건강운동부문 대상 수상',
          '2017 아시아문예대상 산업통상부위원장상 수상 및 홍보대사 선정 ',
          '2017 대한민국청소년대상 지도자대상 수상',
        ]
      },
      {
        month: 10,
        actions: [
          '제1회 밸런스워킹PT 경기대회 개최',
        ]
      },
      {
        month: 9,
        actions: [
          '농협중앙회 도농협동연수원 제7기 도농어울림리더 특별 강연',
          '관악구청장배 에어로빅스체조대회 시범단 공연',
        ]
      },
      {
        month: 8,
        actions: [
          '국립장성숲체원 교직원직무연수',
          '중국 북경 왕징 CBMC 특별 강연',
          '여의도순복음교회 실업인선교회 체육한마당',
        ]
      },
      {
        month: 7,
        actions: [
          '신규 민간자격 등록완료 (밸런스워킹PT노인지도사, 밸런스워킹PT웰니스지도사)',
        ]
      },
      {
        month: 6,
        actions: [
          '3P자기경영연구소 전직원 밸런스워킹PT 지도자 자격 교육',
          '이화여자대학교 ROTC 감사패 수상 및 단체지도자 교육',
          '밸런스워킹PT 순천지사(현 전남동부지사_지사장 박정숙) 개소',
        ]
      },
      {
        month: 5,
        actions: [
          '서울시 동대문구보건소 신체활동리더 역량강화세미나 특별 강연',
          '제7회 단무지MT 참여 _ 공식운동 선정',
          '서울시 영등포학습관 전직원 특별 강연',
          '우크라이나 지부장 특별 연수 진행',
          '2017 아시아파워브랜드 대상 수상',
          '2017 아시아파워리더쉽 대상 수상',
        ]
      },
      {
        month: 4,
        actions: [
          '농협중앙회 도농협동연수원 도농협동운동 확산을 위한 MOU 협약체결',
        ]
      },
      {
        month: 3,
        actions: [
          '이화여자대학교 ROTC 체력단련 프로그램 선정',
          '경기도 김포시 아라항 힐링프로그램지도사 교육',
          '서울시교육청 키쑥쑥건강쑥쑥 프로그램 선정(5개 초등학교 선정)',
        ]
      },
      {
        month: 2,
        actions: [
          '이화여자대학교 ROTC , 3P자기경영연구소와 MOU 협약체결',
        ]
      },
      {
        month: 1,
        actions: [
          '밸런스워킹PT 교사자율연수 시행(교원 단체 심사)',
        ]
      },
    ]
  },
  {
    year: '2016',
    walks: [
      {
        month: 12,
        actions: [
          '2016 아시아문예대상수상',
          '2016 대한민국브랜드대상',
          '2016 대한민국문화교육대상 수상',
        ]
      },
      {
        month: 11,
        actions: [
          '국제휴먼올림픽대회 시범단 공연',
        ]
      },
      {
        month: 10,
        actions: [
          '밸런스워킹PT 창립 1주년 기념식',
        ]
      },
      {
        month: 9,
        actions: [
          '밸런스워킹PT 교육연구소(강남 압구정역 부근) 개소 ',
        ]
      },
      {
        month: 7,
        actions: [
          '국회 기획재정위원회 위원장 조경태 국회의원 간담회',
          '서울시 학교보건진흥원 비만관리 연수 특별 강연',
          '서울시 마포구지부 지부장 임명식 (지부장_황계숙)',
        ]
      },
      {
        month: 6,
        actions: [
          '제1기 밸런스워킹PT 제자대학',
        ]
      },
      {
        month: 5,
        actions: [
          '광주지부(현 호남지부_ 지부장 김대성), 구로구지부(현 구로구지사_지사장 송지연), 마포지사(현 마포구지부_지부장 황계숙) 개소',
          '편백나무숲, 해수욕장 밸런스워킹PT 힐링투어',
          '밸런스워킹PT  특허 상표서비스표 등록',
        ]
      },
      {
        month: 4,
        actions: [
          '목원대학교 ROTC MOU 협약체결 ',
          '국립장성숲체원 힐링 프로그램 특별 강연',
        ]
      },
      {
        month: 3,
        actions: [
          '밸런스워킹PT 야외 웰니스 프로그램 업그레이드 교육',
        ]
      },
      {
        month: 1,
        actions: [
          '서울시 경찰특공대 특별 강연',
        ]
      },
    ]
  },
  {
    year: 2015,
    walks: [
      {
        month: 12,
        actions: [
          '홈커밍데이 행사'
        ]
      },
      {
        month: 9,
        actions: [
          '밸런스워킹PT 평생교육원 개소',
          '수자원 공사 산하 Waterway+ 와  4대강  강문화관 밸런스워킹PT 2개월 강좌 진행 ',
        ]
      },
      {
        month: 8,
        actions: [
          '프로야구 기아타이거즈  소속 선수 대상 특별 강연'
        ]
      },
      {
        month: 7,
        actions: [
          '밸런스워킹 지도사 역량강화 세미나',
        ]
      },
    ]
  }
]