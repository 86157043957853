import '../css/app.scss'

import history from '../config/history'
import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css'

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


function monthToString(month) {
  return monthNames[month - 1];
}

function ly1BannerEvent() {
  $('.ly1-banner').each((_, el) => {
    const $el = $(el);
    $el.find('.slide-control-value--total').html($el.find('.ly1-banner__slide-item').length)
    $el.find('.ly1-banner__slide').slick({
      dots: false,
      prevArrow: $el.find('.slide-control__item--prev'),
      nextArrow: $el.find('.slide-control__item--next'),
    })
    .on('afterChange', function(slick, currentSlide, nextSlide) {
      var i = (currentSlide.currentSlide ? currentSlide.currentSlide : 0) + 1
      $el.find('.slide-control-value--current').html(i)
      $el.find('.ly1-banner__title').html($(currentSlide.$slides[i - 1]).find('img').attr('alt'))
    })
  })
}

function ly1NewsEvent() {
  $('.ly1-news').each((_, el) => {
    const $el = $(el);
    $el.find('.ly1-news__list').slick({
      autoplay: true,
      autoplaySpeed: 2000,
      dots: false,
      arrows: false,
      vertical: true,
    })
  })
}

function slideCardEvent() {
  $('.slide-card').each((_, el) => {
    const $el = $(el);
    $el.find('.slide-control-value--total').html($el.find('.slide-card__item').length)
    $el.find('.slide-card__list').slick({
      dots: false,
      prevArrow: $el.find('.slide-control__item--prev'),
      nextArrow: $el.find('.slide-control__item--next'),
    })
    .on('afterChange', function(slick, currentSlide, nextSlide) {
      var i = (currentSlide.currentSlide ? currentSlide.currentSlide : 0) + 1
      $el.find('.slide-control-value--current').html(i)
    })
  });
}

function ly1HistoryEvent() {
  $('.ly1-history').each((_, el) => {
    const $el = $(el);
    const htmlText = history.map(val => {
      const walkHtml = val.walks.map(walks => {
        const walkItemHtml = walks.actions.map(action => (`
          <li class="ly1-history__walk-list-item">${action}</li>
        `)).join('');

        return `
          <div class="ly1-history__walk">
            <div class="ly1-history__walk-month">${monthToString(walks.month)}</div>
            <ul class="ly1-history__walk-list">
              ${walkItemHtml}
            </ul>
          </div>
        `
      }).join('');

      return `
        <div class="ly1-history__item">
          <div class="ly1-history__item-mark">
            <div class="ly1-history__item-year">${val.year}</div>
          </div>
          <div class="container">
            ${walkHtml}
          </div>
        </div>
      `
    }).join('');

    $el.find('.ly1-history__body').html(htmlText);
  });
}

function ly2OfficeEvent() {
  $('.ly2-office').each((_, el) => {
    const $el = $(el);
    $el.find('.ly2-office__list').slick({
      arrows: false,
      dots: false,
      infinite: false,
      variableWidth: true,
      speed: 300,
    });
  });
}

function ly2BoardEvent() {
  $('.ly2-board').each((_, el) => {
    const $el = $(el);
    const $elTab = $(el).find('.ly2-board__tab');
    const $elSlide = $el.find('.ly2-board__body');

    $elSlide.slick({
      arrows: false,
      dots: false,
      infinite: false,
    })
    .on('afterChange', function(slick, currentSlide, nextSlide) {
      const i = (currentSlide.currentSlide ? currentSlide.currentSlide : 0)

      $elTab.removeClass('ly2-board__tab--active')
      $(el).find('.ly2-board__tab[data-tab='+i+']').addClass('ly2-board__tab--active');
    })

    $elTab.on('click', function() {
      $elSlide.slick('slickGoTo', parseInt($(this).data('tab')) );
    });
  });
}

function ly1NavComponent() {
  $('.ly1-nav__item').on('mouseenter mouseleave', function(e) {
    const $this = $(this);
    const isEnter = e.type === 'mouseenter';
    $('.ly1-nav__item').removeClass('ly1-nav__item--active');

    $this
      .toggleClass('ly1-nav__item--active', isEnter)
      .find('.ly1-nav__sub').stop()
      [isEnter ? 'slideDown' : 'slideUp'](300);
  })
}

function toggleModal(target) {
  const $target = $(`#${target}`);
  $target.toggleClass('modal--active');
  $target.find(' > div').trigger('modalChange', $target.hasClass('modal--active'));
}

function modalComponent() {
  $(document).on('click', '*[data-modal]', function(e) {
    const $this = $(this);

    toggleModal($this.data('modal'));
    e.preventDefault();
  });

  $('.modal').on('click', function() {
    $(this).toggleClass('modal--active', !$(this).hasClass('modal--active'));
  })

  $('.modal > div').on('click', function(e) {
    e.stopPropagation();
  })

  $('.sidebar').on('modalChange', function(e, visible) {
    $(this).toggleClass('sidebar--active', visible);
  })
}


$(() => {
  slideCardEvent();

  ly1BannerEvent();
  ly1NewsEvent();
  ly1HistoryEvent();

  ly2OfficeEvent();
  ly2BoardEvent();
  ly1NavComponent();

  modalComponent();

  // nav_mouseover();
});
